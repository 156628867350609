<template>
  <div class="container mt-4">
    <h4>Logging out</h4>
    <hr />
    <p>Please wait...</p>
  </div>
</template>

<script>
export default {
  auth: true,
  middleware: ['auth'],
  asyncData(context) {
    if (process.server) {
      return context.$auth.logout()
    }
  },
  mounted() {
    this.$auth.logout()
  }
}
</script>
